import { extensionClaimsGetters } from './types'
import { ClaimRequest } from 'Models/ClaimRequest'

export default {
  [extensionClaimsGetters.IS_LOADING]: state => state.isLoading,
  [extensionClaimsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [extensionClaimsGetters.LIST]: state => state.list
    .map(item => new ClaimRequest(item)),
  [extensionClaimsGetters.LIST_OPTS]: state => state.listOpts,
  [extensionClaimsGetters.PAGE_NUMBER]: state => state.pageNumber,

  [extensionClaimsGetters.MANAGED_LOGINS]: state => state.managedLogins,
}
