export function genClaimsState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    listOpts: {},
    pageNumber: 0,
    managedLogins: [],
  }
}

export default genClaimsState
