import { ModelBase } from './ModelBase'
import { Identity } from './Identity'
import { ExtensionVehicle } from './ExtensionVehicle'

export class ClaimRequest extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.copartLogin = this._str(raw.copartLogin)
    this.createdAt = this._date(raw.createdAt)

    const r11s = raw.relationships || {}

    this.identity = ModelBase.modelFromCache(
      Identity,
      r11s.identity || {},
      cache
    )

    this.vehicle = ModelBase.modelFromCache(
      ExtensionVehicle,
      r11s.vehicle || {},
      cache
    )
  }

  get lotSaleId () {
    const [lotId, saleDate] = this.id.split(':')
    return [lotId, saleDate].join(':')
  }
}
