import { extensionClaimsMutations } from './types'

export default {
  [extensionClaimsMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [extensionClaimsMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [extensionClaimsMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [extensionClaimsMutations.SET_LIST_OPTS] (state, value) {
    state.listOpts = value
  },

  [extensionClaimsMutations.PUSH_LIST] (state, value) {
    state.list.push(...value)
  },

  [extensionClaimsMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [extensionClaimsMutations.SET_MANAGED_LOGINS] (state, value) {
    state.managedLogins = value
  },

  [extensionClaimsMutations.REMOVE_LIST_ITEM] (state, value) {
    const itemIndex = state.list.findIndex(item => item.id === value)
    if (itemIndex === -1) return

    state.list.splice(itemIndex, 1)
  },
}
