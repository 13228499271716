import {
  extensionClaimsActions,
  extensionClaimsMutations,
  extensionClaimsGetters
} from './types'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { CLAIMS_PAGE_LIMIT } from '../constants'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionClaimsActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(extensionClaimsMutations.SET_IS_LOADING, true)
    commit(extensionClaimsMutations.SET_LIST_OPTS, opts)

    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getClaimRequests({
        query: createClaimsQuery(opts)
      })
    )

    commit(extensionClaimsMutations.SET_LIST, data)
    commit(extensionClaimsMutations.SET_PAGE_NUMBER, 0)

    commit(extensionClaimsMutations.SET_IS_LOADING, false)
  },

  async [extensionClaimsActions.LOAD_MORE] ({ commit, getters }) {
    if (getters[extensionClaimsGetters.PAGE_NUMBER] === null) return

    commit(extensionClaimsMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[extensionClaimsGetters.PAGE_NUMBER] + 1
    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getClaimRequests({
        query: createClaimsQuery({
          ...getters[extensionClaimsGetters.LIST_OPTS],
          page: { number: pageNumber }
        })
      })
    )

    commit(extensionClaimsMutations.PUSH_LIST, data)
    commit(
      extensionClaimsMutations.SET_PAGE_NUMBER,
      data.length < CLAIMS_PAGE_LIMIT ? null : pageNumber
    )

    commit(extensionClaimsMutations.SET_IS_NEXT_LOADING, false)
  },

  async [extensionClaimsActions.LOAD_MANAGED_LOGINS] ({ commit }) {
    const { data: config } = await sdk.copartBackOffice.getConfig({
      query: {
        // HACK: Hardcode version for admin panel
        filter: { version: '333.0.0' }
      }
    })

    commit(
      extensionClaimsMutations.SET_MANAGED_LOGINS,
      config.allowedToAssignTo
    )
  },

  async [extensionClaimsActions.APPROVE_REQUEST] (
    { commit },
    { id, lotSaleId }
  ) {
    await sdk.copartBackOffice.updateLotSale({
      participationId: id,
      lotSaleId,
    })
    commit(extensionClaimsMutations.REMOVE_LIST_ITEM, id)
  },

  [extensionClaimsActions.SET_IS_LOADING] ({ commit }, value) {
    commit(extensionClaimsMutations.SET_IS_LOADING, value)
  }
}

function createClaimsQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: CLAIMS_PAGE_LIMIT
    },
    include: ['identity', 'identity.basics', 'vehicle']
  }
}
